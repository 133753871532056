.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 200px;
  /* max-width: 1000px; */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  text-align: center;
}

.styled-table thead tr {
  background-color: #224080;
  color: #ffffff;
}

.styled-table th,
.styled-table td {
  padding: 15px 5px;
}

/* .styled-table thead th {
  border: 1px solid #dddddd;
} */

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr.expanded:last-of-type {
  border-bottom: 2px solid #224080;
}

.styled-table tbody tr.active-row {
  color: #224080;
}

.styled-table tbody tr.last-row-but-one-not-expanded {
  opacity: 0.2;
}


.styled-table tbody tr.last-row-not-expanded {
  opacity: 0.1;
}
