.cls-1 {
    letter-spacing: -.03em;
  }

  .cls-2, .cls-3 {
    fill: #004da8;
  }

  .edition-button{
    .cls-2{
      opacity: 1;
      transition: 0.3s;
    }
    cursor: pointer;
  }


  .edition-button:hover{
    .cls-2{
      opacity: 0.6;
    }
  }

  .cls-2, .cls-4 {
    fill-rule: evenodd;
  }

  .cls-5 {
    fill: #fff;
    isolation: isolate;
  }

  .cls-5, .cls-6 {
    font-family: Lato-Black, Lato;
    font-size: 10px;
    font-weight: 800;
  }

  .cls-7 {
    letter-spacing: 0em;
  }

  .cls-8 {
    letter-spacing: -.01em;
  }

  .cls-9 {
    letter-spacing: -.03em;
  }

  .cls-10 {
    letter-spacing: 0em;
  }

  .cls-4 {
    fill: #d9d9d9;
    stroke: #f2f2f2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: .7px;
  }

  .cls-11 {
    letter-spacing: -.1em;
  }

  .cls-12 {
    letter-spacing: 0em;
  }

  .cls-13 {
    letter-spacing: -.02em;
  }