
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  --color: rgba(30, 30, 30);
  --bgColor: rgba(245, 245, 245);
  min-height: 100vh;
  /* display: grid; */
  align-content: center;
  gap: 2rem;
  /* padding: 2rem; */
  color: var(--color);
  background: var(--bgColor);

}

.styled-list ul {
  --col-gap: 1rem;
  --row-gap: 8rem;
  --line-w: 0.25rem;
  display: grid;
  grid-template-columns: var(--line-w) 10fr;
  /* grid-auto-columns: max-content; */
  column-gap: var(--col-gap);
  list-style: none;
  width: min(150rem, 90%);
  margin-inline: auto;
}

/* line */
.styled-list ul::before {
  content: "";
  grid-column: 1;
  grid-row: 1 / span 40;
  background: rgb(225, 225, 225);
  border-radius: calc(var(--line-w) / 2);
}

/* columns*/

/* row gaps */
.styled-list ul li:not(:last-child) {
  margin-bottom: var(--row-gap);
}

/* card */
.styled-list ul li {
  --inlineP: 1.5rem;
  --accent-color: #224080;
  margin-inline: var(--inlineP);
  grid-row: span 2;
}

.styled-list ul li .grid-container {
  display: grid;
  grid-column: 1;
  max-width: 250px;
  grid-template-rows: min-content min-content min-content;
}

/* date */
.styled-list ul li .date {
  --dateH: 3.5rem;
  top: -2rem;
  height: var(--dateH);
  margin-inline: calc(var(--inlineP) * -1);

  text-align: center;
  background-color: var(--accent-color);

  color: white;
  font-size: 1.35rem;
  font-weight: 700;

  display: grid;
  place-content: center;
  position: relative;

  border-radius: calc(var(--dateH) / 2) 0 0 calc(var(--dateH) / 2);
}

/* date flap */
.styled-list ul li .date::before {
  content: "";
  width: var(--inlineP);
  aspect-ratio: 1;
  background: var(--accent-color);
  background-image: linear-gradient(rgba(0, 0, 0, 0.2) 100%, transparent);
  position: absolute;
  top: 100%;

  clip-path: polygon(0 0, 100% 0, 0 100%);
  right: 0;
}

/* circle */
.styled-list ul li .date::after {
  content: "";
  position: absolute;
  width: 2rem;
  aspect-ratio: 1;
  background: var(--bgColor);
  border: 0.3rem solid var(--accent-color);
  border-radius: 50%;
  top: 50%;

  transform: translate(50%, -50%);
  right: calc(100% + var(--col-gap) + var(--line-w) / 2);
}

/* title descr */
.styled-list ul li .title,
.styled-list ul li .descr {
  background: var(--bgColor);
  position: relative;
}
.styled-list ul li .title {
  overflow: hidden;
  /* padding-block-start: 1.5rem;
  padding-block-end: 1rem; */
  font-weight: 500;
}
.styled-list ul li .descr {
  font-weight: 300;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
  border-bottom-left-radius: 15px;
  height: 230px;
  border-bottom-right-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media(min-width: 600px){
  .styled-list ul li .grid-container {
    display: grid;
    grid-column: 1;
    max-width: 300px;
    grid-template-rows: min-content min-content min-content;
  }
}

@media(min-width: 1600px){
  .styled-list ul {
    grid-template-columns: 1fr 1fr var(--line-w) 1fr 1fr;
  }
  .styled-list ul::before {
    grid-column: 3;
  }
  .styled-list ul li:nth-child(odd) {
    grid-column: 1 / span 2;
  }
  .styled-list ul li:nth-child(even) {
    grid-column: 4 / span 2;
  }

  /* start second card */
  .styled-list ul li:nth-child(2) {
    grid-row: 2/4;
  }

  .styled-list ul li .date {
    grid-column: span 2;
  }

  .styled-list ul li .image {
    top: -2rem;
    grid-row: span 2;
  }

  .styled-list ul li:nth-child(odd) .date::before {
    clip-path: polygon(0 0, 100% 0, 100% 100%);
    left: 0;
  }

  .styled-list ul li:nth-child(odd) .date::after {
    transform: translate(-50%, -50%);
    left: calc(100% + var(--col-gap) + var(--line-w) / 2);
  }
  .styled-list ul li:nth-child(odd) .date {
    border-radius: 0 calc(var(--dateH) / 2) calc(var(--dateH) / 2) 0;
  }
  .styled-list ul li .descr {
    top: -2rem;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 0px;
    height: 230px;
  }

  .styled-list ul li .grid-container {
    max-width: none;
  }
}


@media (min-width: 1250px) and (max-width:1600px) {
  .styled-list ul {
    grid-template-columns: 1fr 1fr var(--line-w) 1fr 1fr;
  }
  .styled-list ul::before {
    grid-column: 3;
  }
  .styled-list ul li:nth-child(odd) {
    grid-column: 1 / span 2;
  }
  .styled-list ul li:nth-child(even) {
    grid-column: 4 / span 2;
  }

  /* start second card */
  .styled-list ul li:nth-child(2) {
    grid-row: 2/4;
  }

  .styled-list ul li .date {
    grid-column: span 2;
  }

  .styled-list ul li .image {
    top: -2rem;
    grid-row: span 2;
  }

  .styled-list ul li:nth-child(odd) .date::before {
    clip-path: polygon(0 0, 100% 0, 100% 100%);
    left: 0;
  }

  .styled-list ul li:nth-child(odd) .date::after {
    transform: translate(-50%, -50%);
    left: calc(100% + var(--col-gap) + var(--line-w) / 2);
  }
  .styled-list ul li:nth-child(odd) .date {
    border-radius: 0 calc(var(--dateH) / 2) calc(var(--dateH) / 2) 0;
  }
  .styled-list ul li .descr {
    top: -2rem;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 0px;
    height: 170px;
  }
  .styled-list ul li .grid-container {
    max-width: none;
  }

}
